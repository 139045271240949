:root {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    --transition-duration: 300ms;

}

body * {
    @apply duration-60;
}


.body {
    margin: 0;
}

.app {
    height: 100vh;
    width: 100vw;
}

.menu-container {
    background-image: url(./assets/layered-waves-haikei.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

/* sidebar */

.w-sidebar {
    @apply w-[74px];
}

.sidebar {
    @apply h-screen;
    @apply w-sidebar;
    @apply fixed;
    @apply flex;
    @apply flex-col;
    @apply items-stretch;

    @apply bg-primary/75;
    @apply text-ptext;

    @apply backdrop-blur-[1.5px];
    @apply shadow-[-4px_0_20px_-5px];

    @apply divide-y;
    @apply divide-dotted;
    @apply divide-white;

    @apply duration-250;


    scrollbar-width: none;
}

.sidebar li {
    @apply min-h-[64px];
}

.sidebar-expanded {
    /*@apply w-64;*/
    @apply w-[222px]
}

.sidebar-expanded .rotate {
    transform: rotateZ(-180deg);

}

.on-expand {
    @apply invisible;
    @apply opacity-0;
    @apply duration-200;

}

.sidebar-expanded .on-expand {
    @apply visible;
    @apply opacity-100;
    transform: translateX(1rem);
}

.top-btn {
    @apply invisible;
    @apply opacity-0;
    @apply duration-350;
}

.sidebar-expanded .top-btn {
    @apply visible;
    @apply opacity-100;
}

.buttons {
    @apply h-full;
    @apply overflow-y-auto;
    scrollbar-width: none;
    overflow-x: hidden;
}

.buttons::-webkit-scrollbar {
    width: 0;
}

.buttons:hover::-webkit-scrollbar {
    width: 7px;
}

.buttons:hover::-webkit-scrollbar-thumb {
    background: #4b5563;
    border-radius: 10px;
}


.shrink {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.translate {
    transform: translate(-50%, -50%);
}

.signout {
    transition: none !important;
}

/*main page*/
.messages {
    scrollbar-width: thin;
}

.messages::-webkit-scrollbar {
    width: 0px;
}


.message-input {
    scrollbar-width: none;
}

.message-input::-webkit-scrollbar {
    width: 0;
}

.settings-button {
}

.settings-button.active-button {
    @apply border-ptext/10;
}

.settings-button.active-button .content {
    @apply translate-x-[10%];
}

.incoming-message {
    @apply mr-auto;
}

.incoming-message .message-body {
    @apply border-1.5 border-primary/30;
    @apply bg-white/10;
    @apply text-left;
}

.message-body {
    @apply p-3;
    @apply rounded-3xl;
    @apply whitespace-pre-line;
    @apply break-words;
    @apply bg-primary/80;
    @apply text-right;
    @apply w-fit;
}

.authored-message {
    @apply rounded-bl-md;
}
